import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";

import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { RtlLayoutComponent } from "./layouts/rtl-layout/rtl-layout.component";
import { AuthGuardService as AuthGuard } from 'src/app/core/auth-guard.service';

const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full"
  },
  {
    path: "",
    component: AdminLayoutComponent,
    children: [
      {
        path: "",
        loadChildren:
          "./pages/examples/dashboard/dashboard.module#DashboardModule",
          canActivate: [AuthGuard]
      },
      {
        path: "components",
        loadChildren:
          "./pages/examples/components/components.module#ComponentsPageModule"
      },
      {
        path: "forms",
        loadChildren: "./pages/examples/forms/forms.module#Forms"
      },
      {
        path: "tables",
        loadChildren: "./pages/examples/tables/tables.module#TablesModule"
      },
      {
        path: "maps",
        loadChildren: "./pages/examples/maps/maps.module#MapsModule"
      },
      {
        path: "widgets",
        loadChildren: "./pages/examples/widgets/widgets.module#WidgetsModule"
      },
      {
        path: "charts",
        loadChildren: "./pages/examples/charts/charts.module#ChartsModule"
      },
      {
        path: "calendar",
        loadChildren:
          "./pages/examples/calendar/calendar.module#CalendarModulee"
      },
      {
        path: '',
        loadChildren:
          './pages/stream/stream.module#StreamModule',
          canActivate: [AuthGuard]
      },
      {
        path: '',
        loadChildren:
          './pages/room-create/room-create.module#RoomCreateModule',
          canActivate: [AuthGuard]
      },
      {
        path: '',
        loadChildren:
          './pages/rooms-list/rooms-list.module#RoomsListModule',
          canActivate: [AuthGuard]
      },
      {
        path: '',
        loadChildren:
          './pages/clear-access/clear-access.module#ClearAccessModule',
          canActivate: [AuthGuard]
      },
      {
        path: "",
        loadChildren:
          "./pages/examples/pages/user/user-profile.module#UserModule"
      },
      {
        path: "",
        loadChildren:
          "./pages/examples/pages/timeline/timeline.module#TimelineModule"
      },
    ]
  },
  {
    path: "",
    component: AuthLayoutComponent,
    children: [
      {
        path: "pages",
        loadChildren: "./pages/examples/pages/pages.module#PagesModule"
      },
      {
        path: '',
        loadChildren: './pages/login/login.module#LoginModule'
      }
    ]
  },
  {
    path: "",
    component: RtlLayoutComponent,
    children: [
      {
        path: "pages",
        loadChildren: "./pages/examples/pages/rtl/rtl.module#RtlModule"
      }
    ]
  },
  {
    path: "**",
    redirectTo: "login",
    pathMatch: "full"
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: "enabled",
      anchorScrolling: "enabled",
      scrollOffset: [0, 64]
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
