import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from '../app.settings';

@Injectable({providedIn: 'root'})
export class AccessStreamService {
    constructor(private httpClient: HttpClient) { }

    save(accessStream): Observable<any> {
        return this.httpClient.post<any>(`${AppSettings.API_INSCRIPTIONS}/accesos-stream/`, accessStream);
    }

    update(accessStream, id): Observable<any> {
        return this.httpClient.put<any>(`${AppSettings.API_INSCRIPTIONS}/accesos-stream/${id}`, accessStream);
    }

    getAll(): Observable<any> {
        return this.httpClient.get<any>(`${AppSettings.API_INSCRIPTIONS}/accesos-stream`);
    }

    getByStudentId(studentId, status): Observable<any> {
        return this.httpClient.get<any>(`${AppSettings.API_INSCRIPTIONS}/accesos-stream/${studentId}/${status}`);
    }

    getByComputerCode(computerCode): Observable<any> {
        return this.httpClient.get<any>(`${AppSettings.API_INSCRIPTIONS}/accesos-stream/computer-code/${computerCode}`);
    }
}