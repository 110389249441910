import { Component, OnInit } from "@angular/core";

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  rtlTitle: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  smallTitle?: string;
  rtlTitle: string;
  rtlSmallTitle?: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
}
export interface ChildrenItems2 {
  path?: string;
  smallTitle?: string;
  rtlSmallTitle?: string;
  title?: string;
  rtlTitle: string;
  type?: string;
}
//Menu Items
export const ROUTEStoUSER: RouteInfo[] = [
  {
    path: '/list',
    title: 'Lista de Salas',
    type: 'link',
    icontype: 'tim-icons icon-bullet-list-67',
    rtlTitle: ""
  }
];

export const ROUTES: RouteInfo[] = [
  {
    path: '/create',
    title: 'Crear Sala',
    type: 'link',
    icontype: 'tim-icons icon-pencil',
    rtlTitle: ""
  },
  {
    path: '/list',
    title: 'Lista de Salas',
    type: 'link',
    icontype: 'tim-icons icon-bullet-list-67',
    rtlTitle: ""
  },
  {
    path: '/access',
    title: 'Restablecer Acceso',
    type: 'link',
    icontype: 'tim-icons icon-key-25',
    rtlTitle: ""
  }
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"]
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  isAdministrative = false;

  constructor() {}

  ngOnInit() {
    const user = JSON.parse(sessionStorage.getItem('user'));
    if(user.authorities != null){
      user.authorities.forEach(authority => {
          if (authority.authority === 'administrativo') {
              this.isAdministrative = true;
          }
      });
      if(this.isAdministrative == true){
        this.menuItems = ROUTES.filter(menuItem => menuItem);
      }
      else {
        this.menuItems = ROUTEStoUSER.filter(menuItem => menuItem);
      }
    }
    else {
      this.menuItems = ROUTEStoUSER.filter(menuItem => menuItem);
    }
  }
}
