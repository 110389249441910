import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpEventType, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { AppSettings } from 'src/app/app.settings';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor() { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!req.url.includes(`${AppSettings.API_INSCRIPTIONS}/login`)) {
            let headers = new HttpHeaders();
            headers = headers.append('Authorization', `Bearer ${sessionStorage.getItem('token')}`);
            req = req.clone({
                headers
            });
        }
        return next.handle(req);
    }
}
