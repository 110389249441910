import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private router: Router
  ) {}

  canActivate(): boolean {
    if (sessionStorage.getItem('user')) {
      const user = JSON.parse(sessionStorage.getItem('user'));

      if (user.username ==='SOnline98') {
        if (!sessionStorage.getItem('inscription')) {
          this.router.navigate(['login']);
          return false;
        }
        return true;
      } else {
        if (!sessionStorage.getItem('token')) {
          this.router.navigate(['login']);
          return false;
        }
        return true;
      }
    } else {
      if (!sessionStorage.getItem('token')) {
        this.router.navigate(['login']);
        return false;
      }
      return true;
    }
  }
}
